<template>
  <v-main>
    <v-container fluid>
      <v-row
        justify="center"
        style="min-height: 100vh;"
        :style="$vuetify.breakpoint.mdAndUp ? '' : 'margin-top: 5vh;'"
      >
        <v-col cols="12" md="5" lg="4">
          <v-row justify="center">
            <v-col
              cols="11"
              md="10"
              class="text-center pa-0"
              align-self="center"
              v-if="template.var.logo_only"
            >
              <v-img
                v-if="!template.isDark(template.colors.inline_primary_text)"
                :src="'/img/' + template.var.logo"
                width="75%"
                class="mx-auto"
              ></v-img>
              <v-img
                v-if="template.isDark(template.colors.inline_primary_text)"
                :src="'/img/' + template.var.logo_light"
                width="75%"
                class="mx-auto"
              ></v-img>
              <h2 class="mt-2">REGISTRIERUNG</h2>
            </v-col>
            <v-col
              cols="3"
              md="2"
              class="text-center pa-0"
              align-self="center"
              v-if="!template.var.logo_only"
            >
              <v-img
                class="img-fluid"
                :src="'/img/' + template.var.logo_light"
              ></v-img>
            </v-col>
            <v-col
              cols="4"
              md="auto"
              align-self="center"
              class="py-0"
              v-if="!template.var.logo_only"
            >
              <h2>
                {{ template.var.app_name }}
              </h2>
              <h2 :style="`color: ${template.colors.inline_primary_text}`">
                REGISTRIERUNG
              </h2>
            </v-col>
            <v-col cols="12">
              <v-card
                :color="template.login ? template.login.block : undefined"
                rounded="xl"
                class="pa-2"
              >
                <v-row justify="center">
                  <v-col cols="12">
                    <v-stepper
                      alt-labels
                      v-model="step"
                      class="elevation-0"
                      style="background-color: rgba(0, 0, 0, 0);"
                      v-if="step < 5"
                    >
                      <v-stepper-header>
                        <v-stepper-step
                          step="1"
                          @click="step > 1 ? (step = 1) : (step = step)"
                        >
                          Autorisierung
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step
                          step="2"
                          @click="step > 2 ? (step = 2) : (step = step)"
                        >
                          Verein
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step
                          step="3"
                          @click="step > 3 ? (step = 3) : (step = step)"
                        >
                          Person
                        </v-stepper-step>
                        <v-divider></v-divider>

                        <v-stepper-step step="4">
                          Account
                        </v-stepper-step>
                      </v-stepper-header>
                    </v-stepper>
                  </v-col>
                  <v-fade-transition hide-on-leave>
                    <v-col cols="10" v-if="step == 1">
                      <v-form ref="step1" v-model="valid.step1" lazy-validation>
                        <v-text-field
                          v-model="check.id"
                          filled
                          rounded="xl"
                          dark
                          label="DFBnet-Vereinskennung"
                          :rules="[rules.required]"
                        ></v-text-field>
                        <v-text-field
                          v-model="check.code"
                          filled
                          rounded="xl"
                          dark
                          label="Aktivierungscode"
                          persistent-hint
                          hint="Beispiel: 50xxxxxxx"
                          :rules="[rules.required]"
                        ></v-text-field>
                        <v-btn
                          block
                          dark
                          large
                          rounded="xl"
                          class="mt-4"
                          :color="template.colors.primary"
                          :style="`color: ${template.colors.inline_primary_text};`"
                          :loading="loader"
                          @click="check_auth()"
                        >
                          WEITER
                        </v-btn>
                      </v-form>
                    </v-col>
                  </v-fade-transition>
                  <v-scroll-x-reverse-transition hide-on-leave>
                    <v-col cols="10" v-if="step == 2 && !art">
                      <h3 class="text-center">
                        Wie möchtest du dich registrieren?
                      </h3>
                      <v-card
                        color="white"
                        light
                        class="my-5 pa-0"
                        link
                        @click="art = 'email'"
                      >
                        <v-row justify="center" class="pa-0">
                          <v-col cols="12" align-self="center" class="py-2">
                            <h4>
                              <v-icon class="mx-2" large>mdi-at</v-icon>
                              Mit E-Mail registrieren
                            </h4>
                          </v-col>
                        </v-row>
                      </v-card>
                      <v-card
                        color="#4285F4"
                        dark
                        link
                        class="my-5 pa-0"
                        @click="g_signup()"
                      >
                        <v-row class="pa-0">
                          <v-col cols="auto" class="py-0 mr-0 pr-0">
                            <v-img src="/img/google-login.png" width="50" />
                          </v-col>
                          <v-col align-self="center">
                            <h4>Mit Google registrieren</h4>
                          </v-col>
                        </v-row>
                      </v-card>
                      <v-card
                        color="#3b5998"
                        link
                        class="my-5 pa-0"
                        @click="fb_signup()"
                      >
                        <v-row justify="center" class="pa-0 pr-2">
                          <v-col cols="12" align-self="center" class="py-2">
                            <h4>
                              <v-icon class="mx-2" large>mdi-facebook</v-icon>
                              Mit Facebook registrieren
                            </h4>
                          </v-col>
                        </v-row>
                      </v-card>
                      <v-card
                        color="#00acee"
                        link
                        class="my-5 pa-0"
                        @click="twitter_signup()"
                      >
                        <v-row justify="center" class="pa-0 pr-2">
                          <v-col cols="12" align-self="center" class="py-2">
                            <h4>
                              <v-icon class="mx-2" large>mdi-twitter</v-icon>
                              Mit Twitter registrieren
                            </h4>
                          </v-col>
                        </v-row>
                      </v-card>
                      <v-card
                        color="#FFFFFF"
                        light
                        link
                        class="my-5 pa-0"
                        @click="ms_signup()"
                        v-if="false"
                      >
                        <v-row class="pa-0">
                          <v-col
                            cols="auto"
                            class="py-0 ml-1 mr-0 pr-0"
                            align-self="center"
                          >
                            <v-img src="/img/microsoft-login.svg" width="40" />
                          </v-col>
                          <v-col align-self="center">
                            <h4>Mit Microsoft-Konto registrieren</h4>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-scroll-x-reverse-transition>
                  <v-scroll-x-reverse-transition hide-on-leave>
                    <v-col cols="10" v-if="step == 4">
                      <v-form ref="step4" v-model="valid.step4" lazy-validation>
                        <v-text-field
                          v-model="regdaten.email"
                          filled
                          rounded="xl"
                          dark
                          label="E-Mail Adresse"
                          :rules="[rules.required, rules.email]"
                        ></v-text-field>
                        <v-text-field
                          v-model="regdaten.password"
                          filled
                          rounded="xl"
                          type="password"
                          dark
                          label="Passwort"
                          :rules="[rules.required, rules.password]"
                        ></v-text-field>
                        <v-text-field
                          v-model="regdaten.passwordconfirm"
                          filled
                          rounded="xl"
                          type="password"
                          dark
                          label="Passwort wiederholen"
                          :rules="[rules.required, rules.passwordconfirm]"
                        ></v-text-field>
                      </v-form>
                      <v-btn
                        class="mt-5"
                        block
                        dark
                        rounded="xl"
                        large
                        :color="template.colors.primary"
                        :style="`color: ${template.colors.inline_primary_text};`"
                        :loading="creating_account"
                        @click="create_account_mail()"
                      >
                        WEITER
                      </v-btn>
                    </v-col>
                  </v-scroll-x-reverse-transition>
                  <v-scroll-x-reverse-transition hide-on-leave>
                    <v-col cols="10" v-if="step == 2">
                      <v-text-field
                        v-model="regdaten.verein.name"
                        filled
                        rounded="xl"
                        dark
                        label="Vereinsname"
                      ></v-text-field>
                      <v-divider class="mt-1 mb-4"></v-divider>
                      <v-row justify="center">
                        <v-col cols="4" align-self="center">
                          <v-img
                            :src="regdaten.verein.logo"
                            width="100%"
                          ></v-img>
                        </v-col>
                        <v-col cols="8" align-self="center">
                          <v-btn
                            block
                            rounded="xl"
                            outlined
                            color="white"
                            @click.stop="logo.dialog = true"
                          >
                            LOGO HOCHLADEN
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-dialog
                        v-model="logo.dialog"
                        :max-width="
                          $vuetify.breakpoint.mdAndUp ? '700' : 'none'
                        "
                        :overlay-opacity="
                          $vuetify.breakpoint.mdAndUp ? 0.85 : undefined
                        "
                        :hide-overlay="
                          $vuetify.breakpoint.mdAndUp ? false : true
                        "
                        :transition="
                          $vuetify.breakpoint.mdAndUp
                            ? 'dialog-transition'
                            : 'dialog-bottom-transition'
                        "
                        :fullscreen="$vuetify.breakpoint.mdAndUp ? false : true"
                        :width="$vuetify.breakpoint.mdAndUp ? 'auto' : '100vw'"
                      >
                        <v-card>
                          <v-toolbar
                            :dark="!template.light"
                            :color="template.colors.primary"
                            :style="`color: ${template.colors.inline_primary_text};`"
                          >
                            <v-btn
                              icon
                              :dark="!template.light"
                              @click="closeDialog()"
                            >
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <v-toolbar-title>Logo hochladen</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-toolbar-items>
                              <v-btn
                                dark
                                text
                                :loading="logo.uploading"
                                @click="upload()"
                              >
                                Hochladen
                              </v-btn>
                            </v-toolbar-items>
                          </v-toolbar>
                          <v-card-text>
                            <v-row justify="center" class="my-4">
                              <v-col :cols="logo.imageData ? 12 : 10">
                                <v-image-input
                                  v-model="logo.imageData"
                                  :image-quality="0.85"
                                  clearable
                                  accept="image/*"
                                  :hideActions="logo.imageData ? false : true"
                                  :imageWidth="
                                    $vuetify.breakpoint.mdAndUp ? 256 : 192
                                  "
                                  :imageMinScaling="'contain'"
                                  :imageHeight="
                                    $vuetify.breakpoint.mdAndUp ? 256 : 192
                                  "
                                  :full-width="true"
                                  image-format="png"
                                />
                              </v-col>
                              <v-col
                                cols="12"
                                class="text-center"
                                v-if="!logo.imageData"
                              >
                                Für eine gute Darstellung wähle bitte ein Bild
                                mit mindestens 256 x 256 Pixeln.
                              </v-col>
                            </v-row>
                          </v-card-text>
                          <v-card-actions>
                            <v-btn
                              :color="template.colors.primary"
                              :style="`color: ${template.colors.inline_primary_text};`"
                              :loading="logo.uploading"
                              @click="upload()"
                            >
                              Speichern
                            </v-btn>
                            <v-btn color="secondary" @click="closeDialog()">
                              Abbrechen
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <v-divider class="my-4"></v-divider>
                      <v-text-field
                        v-model="regdaten.alias"
                        filled
                        rounded="xl"
                        dark
                        label="Link-Name"
                        :success-messages="linkok"
                        :error-messages="linkexist"
                        @input="
                          linkok = ''
                          linkexist = ''
                        "
                      ></v-text-field>
                      <span class="mt-2 mb-5">
                        Zuschauer können sich für deine Veranstaltungen dann
                        über diesen Link registrieren:
                        <br />
                        <b>{{ template.var.app_url }}/{{ regdaten.alias }}</b>
                      </span>
                      <v-divider class="my-4"></v-divider>
                      <v-form ref="step2" v-model="valid.step2" lazy-validation>
                        <v-text-field
                          v-model="regdaten.sportstaette"
                          filled
                          rounded="xl"
                          dark
                          label="Name deiner Sportstätte"
                          hint="Du kannst weitere Sportstätten in der Vereinsverwaltung hinzufügen"
                          persistent-hint
                          :rules="[rules.required]"
                        ></v-text-field>
                        <v-divider class="my-4"></v-divider>
                        <v-btn
                          class="mt-5"
                          block
                          rounded="xl"
                          dark
                          large
                          :color="template.colors.primary"
                          :style="`color: ${template.colors.inline_primary_text};`"
                          :loading="linkloading"
                          @click="check_link()"
                        >
                          WEITER
                        </v-btn>
                      </v-form>
                    </v-col>
                  </v-scroll-x-reverse-transition>
                  <v-scroll-x-reverse-transition hide-on-leave>
                    <v-col cols="10" v-if="step == 3">
                      <v-form ref="name" v-model="valid.step3" lazy-validation>
                        <v-text-field
                          v-model="regdaten.vorname"
                          filled
                          rounded="xl"
                          dark
                          label="Dein Vorname"
                          :rules="[rules.required, rules.name]"
                        ></v-text-field>
                        <v-text-field
                          v-model="regdaten.nachname"
                          filled
                          rounded="xl"
                          dark
                          label="Dein Nachname"
                          :rules="[rules.required, rules.name]"
                        ></v-text-field>
                      </v-form>
                      <v-divider class="my-4"></v-divider>
                      <v-btn
                        class="mt-5"
                        block
                        rounded="xl"
                        dark
                        large
                        :color="template.colors.primary"
                        :style="`color: ${template.colors.inline_primary_text};`"
                        :loading="loader"
                        @click="save_userdaten()"
                      >
                        WEITER
                      </v-btn>
                    </v-col>
                  </v-scroll-x-reverse-transition>
                  <v-scroll-x-reverse-transition hide-on-leave>
                    <v-col cols="10" v-if="step == 5">
                      <h2>Registrierung erfolgreich!</h2>
                      <span>
                        Du hast erfolgreich einen Account für deinen Verein
                        {{ regdaten.verein.name }} erstellt. In Kürze erhältst
                        du eine Bestätigungsemail an
                        <b>{{ regdaten.email }}</b>
                        mit einer Kurzanleitung zur Einrichtung des Accounts.
                      </span>
                      <v-btn
                        :color="template.colors.primary"
                        :style="`color: ${template.colors.inline_primary_text};`"
                        large
                        class="mt-4"
                        block
                        to="/login"
                      >
                        Zum Login
                      </v-btn>
                    </v-col>
                  </v-scroll-x-reverse-transition>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-row justify="center" class="my-5 pb-5">
            <v-col cols="12">
              <v-card
                :color="template.colors.blocks"
                class="rounded-xl pa-4"
                :style="`color: ${template.colors.block_text};`"
              >
                <v-row justify="center">
                  <v-col align-self="center">
                    <v-divider
                      class="my-3"
                      :style="`border-color: ${template.colors.block_text};`"
                    ></v-divider>
                  </v-col>
                  <v-col cols="auto">
                    PROBLEME?
                  </v-col>
                  <v-col align-self="center">
                    <v-divider
                      class="my-3"
                      :style="`border-color: ${template.colors.block_text};`"
                    ></v-divider>
                  </v-col>
                  <v-col cols="10" class="text-center">
                    <span>
                      Dann hilft dir vielleicht unsere Anleitung weiter
                    </span>
                  </v-col>
                  <v-col cols="10">
                    <v-btn
                      block
                      :color="template.colors.block_text"
                      large
                      outlined
                      :href="
                        template.var.app_url +
                        '' +
                        template.var.anleitungen.registrierung
                      "
                      target="_blank"
                    >
                      ANLEITUNG ALS PDF
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col
              cols="10"
              class="text-center"
              v-if="$vuetify.breakpoint.mdAndDown"
            >
              <v-btn text color="white" large to="/datenschutz">
                Datenschutz
              </v-btn>
            </v-col>
            <v-col
              cols="10"
              class="text-center"
              v-if="$vuetify.breakpoint.mdAndDown"
            >
              <v-btn text color="white" large to="/impressum">Impressum</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
      :color="template.colors.primary"
      :dark="!template.light"
      bottom
      v-model="error"
    >
      {{ errortext }}
    </v-snackbar>
  </v-main>
</template>

<script>
//import firebase from '../../firebaseConfig'
import store from '../../store'
import router from '../../routes'
import logout from '../../auth/auth'
import { mapGetters } from 'vuex'

export default {
  name: 'Login',
  data() {
    return {
      art: 'email',
      check: {
        id: '',
        code: '',
      },
      verein: '',
      show: false,
      valid: {
        step1: false,
        step2: false,
        step3: false,
        step4: false,
      },
      form: {
        email: '',
        password: '',
      },
      regdaten: {
        rolle: 'Vorstand',
        verein: {
          logo:
            'https://firebasestorage.googleapis.com/v0/b/flvw-covid-tracker.appspot.com/o/Logos%2Fdefault_logo.png?alt=media&token=7a59fe5c-9b5a-46dc-a85f-3351fd79d31a',
          name: '',
          website: '',
        },
        nachname: '',
        vorname: '',
        sportstaette: '',
        alias: '',
        maxzuschauer: '300',
        email: '',
        password: '',
        passwordconfirm: '',
      },
      linkok: '',
      linkexist: '',
      linkloading: false,
      creating_account: false,
      step: 1,
      loader: false,
      error: false,
      errortext: '',
      title: [
        'AUTORISIERUNG',
        'VEREINSDATEN',
        'ACCOUNTDATEN',
        'ZUGANGSDATEN',
        'ERFOLGREICH',
      ],
      logo: {
        dialog: false,
        uploading: false,
        imageData: '',
      },
      rules: {
        required: (value) => !!value || 'Bitte gebe diese Daten ein',
        name: (v) =>
          v.length >= 2 ? true : 'Bitte gebe einen gültigen Namen ein',
        password: (v) =>
          v.length >= 6
            ? true
            : 'Dein Passwort sollte mindestens 6 Zeichen lang sein',
        passwordconfirm: (v) =>
          v == this.regdaten.password
            ? true
            : 'Die Passwörter stimmen nicht überein',
        street: (v) =>
          v.length >= 5 ? true : 'Bitte gebe einen gültigen Straßennamen ein',
        streetnr: (v) =>
          v.match(/[0-9]{1,}/)
            ? true
            : 'Bitte gebe eine gültige Hausnummer ein',
        telefon: (v) =>
          v.match(/[0-9]{5,}/)
            ? true
            : 'Bitte gebe eine gültige Telefonnummer ein',
        plz: (v) =>
          v.match(/[0-9]{5}/)
            ? true
            : 'Bitte gebe eine gültige Postleitzahl ein',
        email: (v) =>
          v.match(
            /^((?!\.)[\w-_.]*[^.])(@\w+)((?!\.)[\w-_.]*[^.])(\.\w+(\.\w+)?[^.\W])$/,
          )
            ? true
            : 'Bitte gebe eine gültige E-Mail Adresse ein',
      },
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      template: 'template',
    }),
  },
  mounted() {},
  methods: {
    save_userdaten() {
      if (this.$refs.name.validate()) {
        this.step = 4
      }
    },
    save_vereinsdaten() {
      if (
        this.regdaten.verein.name &&
        this.regdaten.verein.logo &&
        this.regdaten.alias &&
        this.regdaten.sportstaette
      ) {
        this.step = 3
      }
    },
    async check_link() {
      if (this.$refs.step2.validate()) {
        this.linkloading = true
        if (this.regdaten.alias.match(/(\\|\/){1,}/)) {
          this.linkloading = false
          this.linkexist = 'Der eingegebene Link enthält unzulässige Zeichen'
          return
        }
        var functions = firebase.app().functions('europe-west3')
        var checkauth = functions.httpsCallable('checkAlias')
        checkauth({ alias: this.regdaten.alias })
          .then((result) => {
            if (result.data.check) {
              this.linkloading = false
              this.linkok = 'Dieser Link-Name ist verfügbar'
              this.linkexist = ''
              this.save_vereinsdaten()
            } else {
              this.linkloading = false
              this.linkexist = 'Es existiert bereits ein Link mit diesem Namen'
              this.linkok = ''
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
    closeDialog() {
      this.logo = {
        dialog: false,
        uploading: false,
        imageData: '',
      }
    },
    upload() {
      if (this.logo.imageData) {
        this.regdaten.verein.logo = this.logo.imageData
        this.logo.uploading = false
        this.logo.imageData = ''
        this.logo.dialog = false
      }
    },
    create_account_mail() {
      if (this.$refs.step4.validate()) {
        this.creating_account = true
        var functions = firebase.app().functions('europe-west3')
        var addUser = functions.httpsCallable('addVereinsAccount')
        addUser({
          user: {
            email: this.regdaten.email,
            password: this.regdaten.password,
            vorname: this.regdaten.vorname,
            nachname: this.regdaten.nachname,
            verein: {
              name: this.regdaten.verein.name,
              logo: this.regdaten.verein.logo,
            },
            verband: this.regdaten.verband,
            alias: this.regdaten.alias,
            sportstaetten: [
              {
                name: this.regdaten.sportstaette,
                main: true,
              },
            ],
          },
          check: {
            id: this.check.id,
            code: this.check.code,
          },
        })
          .then((result) => {
            if (result.data.success) {
              this.step = 5
              this.creating_account = true
            } else {
              this.error = true
              this.creating_account = false
              if (
                result.data.errortext.errorInfo.code ==
                'auth/email-already-exists'
              ) {
                this.errortext =
                  'Es ist bereits ein User mit dieser E-Mail Adresse vorhanden. Versuche es mit einer anderen E-Mail Adresse noch einmal.'
              } else {
                this.errortext = result.data.errortext.errorInfo.message
              }
            }
          })
          .catch((error) => {
            this.error = true
            console.log(error)
            this.errortext = error
          })
      }
    },
    async check_auth() {
      if (this.$refs.step1.validate()) {
        this.loader = true
        this.error = false
        this.errortext = ''
        if (this.check.id && this.check.code) {
          if (this.check.code.length == 7) {
            this.check.code = '50' + this.check.code
          } else if (
            this.check.code.length == 10 &&
            this.check.code.startsWith('050')
          ) {
            this.check.code = this.check.code.substr(1)
          }

          if (this.check.id.startsWith('PV')) {
            this.check.id = this.check.id.replace('PV', '')
          }
          if (
            this.check.id.startsWith('pv') ||
            this.check.id.startsWith('Pv')
          ) {
            this.check.id = this.check.id.replace('pv', '')
          }
          if (this.check.id.startsWith('Pv')) {
            this.check.id = this.check.id.replace('Pv', '')
          }

          var functions = firebase.app().functions('europe-west3')
          var checkauth = functions.httpsCallable('checkRegistrierung')
          checkauth({
            check: { id: this.check.id, code: this.check.code },
          }).then((result) => {
            if (!result.data.error) {
              if (result.data.verband == this.template.var.verband) {
                this.regdaten.verein.name = result.data.verein
                this.regdaten.alias = result.data.alias
                this.regdaten.verband = result.data.verband
                this.step = 2
                this.loader = false
              } else {
                this.loader = false
                this.error = true
                this.errortext =
                  'Eine Registrierung ist mit diesen Daten nicht möglich.'
              }
            } else {
              this.loader = false
              this.error = true
              this.errortext = result.data.errortext
            }
          })
        }
      }
    },
    submit() {
      this.loader = true
      this.error = null
      firebase
        .auth()
        .signInWithEmailAndPassword(this.form.email, this.form.password)
        .catch((err) => {
          this.loader = false
          this.error = true
          this.errortext = err
        })
    },
  },
}
</script>
>
